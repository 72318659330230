import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { Layout, SEO, SectionContainerSC } from '@components';
import { getNavItems } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';

const IFrame = styled.iframe`
  border: unset;
`;

const SpecialDiscountsPageTemplate = ({ location, pageContext: { iframe, pageName } }) => {
  const { t } = useTranslation();

  return (
    <Layout
      location={location}
      navProps={{
        navItems: getNavItems({
          t,
        }),
      }}
    >
      <SEO
        title={t(`specialDiscounts:metaData.${pageName}.title`)}
        description={t(`specialDiscounts:metaData.${pageName}.description`, {
          serverCount: SERVER_COUNT,
        })}
        keywords={['Atlas VPN discount']}
      />
      <SectionContainerSC>
        <IFrame height={720} src={iframe} />
      </SectionContainerSC>
    </Layout>
  );
};

export default SpecialDiscountsPageTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "specialDiscounts"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
